import merge from 'lodash/merge'
// import { itemCommonUtilis } from 'item-component'
import { envExternalConfigs } from './apiConfigExternal'
import {
  isPartnerOnline,
  LoginRedirectPath,
  findAppPath,
} from '../globalUtils/searchUtills'

const apiKey = '8d3b2848ce5f16aac9016898d5d5721cc47fb81e'
const apiKeyProduction = 'd538fd2dc47b9df208428854cc991f6646ae3350'
const commonConfig = {
  auth: {
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    storageType: 'localStorage',
  },
  analytics: {
    appName: 'LaunchPad',
    url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    retries: 1,
    schemaId: '1961',
    persistMethod: 'localStorage',
    apiKey,
  },
}

const envConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .vela.yml
  local: {
    auth: {
      clientId: 'launchpad_npe_im',
      loginRedirect: `${window.location.origin}/auth/login`,
    },
    apiKey,
    graphql: {
      uri: 'http://localhost:9001/graphql',
    },
    debug: {
      enableReduxDevTools: true,
      enableNetworkLogging: true,
      networkOptions: {
        /* WARNING: Memory intensive! Only enable for testing or severe bugs. */
        body: true,
      },
    },
    pipeline: {
      baseUrl: 'https://pipeline.perf.target.com/v2/',
      detailsPath: 'images/search/?navigationAction=manageImages&tcins=',
    },
    launchpad: {
      host: 'http://localhost:3000',
      page: {
        dashboard: '/',
        maintain: '/maintain',
        create: '/create',
        bulk: '/bulk',
        admin: '/admin',
        relationship: '/relationship',
        iau: '/item-attribute-update-request',
      },
    },
    massMaintain: {
      host: 'https://uimititem.dev.target.com',
    },
    groups: {
      host: 'https://grouperui.dev.target.com',
    },
    opentask: {
      host: 'http://localhost:3000/oam/',
    },
    support: {
      host: `https://targetprod.service-now.com/merch_support`,
    },
  },
  dev: {
    auth: {
      clientId: 'launchpad_npe_im',
      loginRedirect: LoginRedirectPath(),
    },
    apiKey,
    graphql: {
      uri: 'https://stgapi-internal.target.com/graphql_launchpad/v1',
    },
    debug: {
      enableReduxDevTools: true,
      enableNetworkLogging: true,
      networkOptions: {
        /* WARNING: Memory intensive! Only enable for testing or severe bugs. */
        body: true,
      },
    },
    pipeline: {
      baseUrl: 'https://pipeline.perf.target.com/v2/',
      detailsPath: 'images/search/?navigationAction=manageImages&tcins=',
    },
    launchpad: {
      // host: 'https://xycpre-launchpad.target.com/v2',
      host: '/v2',
      page: {
        dashboard: '/',
        maintain: '/maintain',
        create: '/create',
        bulk: '/bulk',
        admin: '/admin',
        relationship: '/relationship',
        iau: '/item-attribute-update-request',
      },
    },
    massMaintain: {
      host: 'https://uimititem.dev.target.com',
    },
    groups: {
      host: 'https://grouperui.dev.target.com',
    },
    opentask: {
      host: 'https://xycpre-launchpad.target.com/opentask/',
    },
    support: {
      host: `https://targetprod.service-now.com/merch_support`,
    },
  },
  stg: {
    auth: {
      clientId: 'launchpad_npe_im',
      loginRedirect: LoginRedirectPath(),
    },
    apiKey,
    graphql: {
      uri: 'https://stgapi-internal.target.com/graphql_launchpad/v1',
    },
    debug: {
      enableReduxDevTools: true,
      enableNetworkLogging: true,
      networkOptions: {
        /* WARNING: Memory intensive! Only enable for testing or severe bugs. */
        body: false,
      },
    },
    pipeline: {
      baseUrl: 'https://pipeline.perf.target.com/v2/',
      detailsPath: 'images/search/?navigationAction=manageImages&tcins=',
    },
    launchpad: {
      // host: 'https://xycpre-launchpad.target.com/v2',
      host: `${findAppPath()}/v2`,
      page: {
        dashboard: '/',
        maintain: '/maintain',
        create: '/create',
        bulk: '/bulk',
        admin: '/admin',
        relationship: '/relationship',
        iau: '/item-attribute-update-request',
      },
    },
    massMaintain: {
      host: 'https://uimititem.dev.target.com',
    },
    groups: {
      host: 'https://grouperui.dev.target.com',
    },
    opentask: {
      host: 'https://xycpre-launchpad.target.com/opentask/',
    },
    support: {
      host: `https://targetprod.service-now.com/merch_support`,
    },
  },
  prod: {
    auth: {
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'launchpad_prod_im',
      loginRedirect: LoginRedirectPath(),
    },
    apiKey: apiKeyProduction,
    analytics: {
      url: 'https://api.target.com/consumers/v1/ingest/internal/internal_app',
      apiKey: apiKeyProduction,
    },
    graphql: {
      uri: 'https://api-internal.target.com/graphql_launchpad/v1',
    },
    debug: {
      enableReduxDevTools: true,
      enableNetworkLogging: false,
      networkOptions: {
        /* WARNING: Memory intensive! Only enable for testing or severe bugs. */
        body: false,
      },
    },
    pipeline: {
      baseUrl: 'https://pipeline.target.com/v2/',
      detailsPath: 'images/search/?navigationAction=manageImages&tcins=',
    },
    launchpad: {
      // host: 'https://launchpad.target.com/v2',
      host: `${findAppPath()}/v2`,
      page: {
        dashboard: '/',
        maintain: '/maintain',
        create: '/create',
        bulk: '/bulk',
        admin: '/admin',
        relationship: '/relationship',
        iau: '/item-attribute-update-request',
      },
    },
    massMaintain: {
      host: 'https://uimititem-prod.prod.target.com',
    },
    groups: {
      host: 'https://grouperui.prod.target.com',
    },
    opentask: {
      host: 'https://launchpad.target.com/opentask/',
    },
    support: {
      host: `https://targetprod.service-now.com/merch_support`,
    },
  },
}

const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
let apiConfig = merge(commonConfig, config)
if (isPartnerOnline()) {
  apiConfig = merge({}, apiConfig, envExternalConfigs[appEnv])
}

export default apiConfig
